import { Head } from '../../../components/Head'
import { SnackTitle } from '../../../components/SnackTitle'

export default function Pizzas() {

    return (
        <>
            <Head title='Cabo USB para Celular' />
            <SnackTitle>Cabo USB para Celular</SnackTitle>
        </>
    )
}