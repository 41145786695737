import axios from 'axios'

import { SnackData } from '../interfaces/SnackData'

const api = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL,
})

export const getBurgers = () => api.get<SnackData[]>('/items?company=Paulinho%20Celulares&category=Lancamentos')
export const getPizzas = () => api.get<SnackData[]>('/items?company=Paulinho%20Celulares&category=Cabos')
export const getDrinks = () => api.get<SnackData[]>('/items?company=Paulinho%20Celulares&category=Displays')
export const getIceCreams = () => api.get<SnackData[]>('/items?company=Paulinho%20Celulares&category=Carregadores')

export default api
