//import { useSnack } from '../../../hooks/useSnack'
//import { Snacks } from '../../../components/Snacks'
import { Head } from '../../../components/Head'
import { SnackTitle } from '../../../components/SnackTitle'

export default function Drinks() {


    //const { burgers } = useSnack()

    return (
        <>
            <Head title='Caculadora' />
            
            <SnackTitle>Caculadora</SnackTitle>
           
        </>
    )
}






