//import { useSnack } from '../../../hooks/useSnack'

import { Head } from '../../../components/Head'
//import { Snacks } from '../../../components/Snacks'
import { SnackTitle } from '../../../components/SnackTitle'

export default function Pizzas() {


  return (
    <>
      <Head title='Carregador para Celulares' />
      <SnackTitle>Carregador para Celulares</SnackTitle>

    </>
  )
}