import { createContext, ReactNode, useEffect, useState } from 'react'

import { SnackData } from '../interfaces/SnackData'

import { getBurgers, getDrinks, getIceCreams, getPizzas } from '../services/api'

interface SnackContextProps {
  burgers: SnackData[]
  pizzas: SnackData[]
  drinks: SnackData[]
  iceCreams: SnackData[]
  loading: boolean // Adicionando estado de carregamento
  error: string | null // Adicionando estado de erro
}

interface SnackProviderProps {
  children: ReactNode
}

export const SnackContext = createContext({} as SnackContextProps)

export function SnackProvider({ children }: SnackProviderProps) {
  const [burgers, setBurgers] = useState<SnackData[]>([])
  const [pizzas, setPizzas] = useState<SnackData[]>([])
  const [drinks, setDrinks] = useState<SnackData[]>([])
  const [iceCreams, setIceCreams] = useState<SnackData[]>([])
  const [loading, setLoading] = useState(true) // Estado de carregamento
  const [error, setError] = useState<string | null>(null) // Estado de erro

  useEffect(() => {
    ; (async () => {
      try {
        setLoading(true)
        const [burgerRequest, pizzaRequest, drinkRequest, iceCreamRequest] = await Promise.all([
          getBurgers(),
          getPizzas(),
          getDrinks(),
          getIceCreams(),
        ])

        setBurgers(burgerRequest.data)
        setPizzas(pizzaRequest.data)
        setDrinks(drinkRequest.data)
        setIceCreams(iceCreamRequest.data)
      } catch (err) {
        setError('Erro ao carregar os dados. Por favor, tente novamente.')
        console.error(err)
      } finally {
        setLoading(false) // Certifique-se de parar o estado de carregamento
      }
    })()
  }, [])

  return (
    <SnackContext.Provider value={{ burgers, pizzas, drinks, iceCreams, loading, error }}>
      {children}
    </SnackContext.Provider>
  )
}
