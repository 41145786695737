import { Head } from '../../../components/Head'
import { SnackTitle } from '../../../components/SnackTitle'
export default function Pizzas() {

    return (
        <>
            <Head title='Baterias para Celulares' />
            <SnackTitle>Baterias para Celulares</SnackTitle>

        </>
    )
}